import React, { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import axios from "axios";
import { FaUserShield, FaUsersCog, FaLaptop, FaBolt, FaHistory } from "react-icons/fa";

const Dashboard = () => {
  const [userBal, setUserBal] = useState('');
  const [todayRecharge, setTodayRecharge] = useState('');
  const [todayRechargeAmount, setTodayRechargeAmount] = useState('');
  const [superAdminName, setSuperAdminName] = useState('');
  const [mobileNumber, setMobileNumber] = useState('');

  const navigate = useNavigate();
  const fnAdminClick = (e) => {
    //saveTokenInLocalStorage(response.data.results.token);
    navigate("/SuperAdmin/ManageAdmin");
  }
  const fnManageOrganizationClick = (e) => {
    navigate("/SuperAdmin/ManageOrganization");
  }

  const fnCreateDeviceClick = (e) => {
    navigate("/SuperAdmin/CreateDevice");
  }

  const fnCreateCardClick = (e) => {
    navigate("/SuperAdmin/CreateCard");
  }


  useEffect(() => {
    let TokenVal = localStorage.getItem('jwtToken');
    fnDashboardLoad();
    if (TokenVal === null || TokenVal === "" || TokenVal === undefined) {
      navigate("/");
    }
  });

  function fnDashboardLoad() {
    const BASE_URL = window.BASE_URL + '/get_dashboardInfo';
    var config = {
      method: 'get',
      url: BASE_URL,
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem('jwtToken'),
      }
    };

    axios.request(config)
      .then(function (response) {
        //setAdminDdl([]);

        if (response != null && response != undefined && response.data != null && response.data != undefined && response.data.results != null && response.data.results != undefined) {
          //setAdminDdl(response.data.results);
          setUserBal(response.data.results.userBalance);
          setTodayRecharge(response.data.results.todaysRecharges);
          setTodayRechargeAmount(response.data.results.rechargeAmount);
          if (response.data.results.userInfo != null && response.data.results.userInfo != undefined) {
            setSuperAdminName(response.data.results.userInfo.name);
            setMobileNumber(response.data.results.userInfo.mobile);
          }
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }


  return (
    <div className="container">
      <div className="form-outer p-2">
        <div className="dashboard">
          
            <h1 className="px-3 mt-3">Manage Admin</h1>
            
        

          <div className="recharge-dashboard">


            <div className="">
              <div className="balance-content">
              <div className="text-end bb-m"><span className="balance-title">Max Print Tech</span></div>
                <div className="balance-units">Balance Units</div>
                <div className="balance-value">₹<label>{userBal}</label></div>

                <div className="super-admin mt-4">
                  <span className="span-title">Super Admin Name</span>
                  <label className="label-title fw-bold">{superAdminName}</label>
                </div>

                <div className="mobile mt-4">
                <span className="span-title">Mobile</span>
                  <label className="label-title fw-bold">{mobileNumber}</label>
                </div>
              </div>
            </div>
          </div>
          <div className="row mb-2">
            <div className="col-6">
              <div className="recharge-tile">                
                <div className="summary-subtitle"><FaBolt className="icon-recharge"/><span className="summary-title">{todayRecharge}</span> Today's Recharge</div>
              </div>
            </div>
            <div className="col-6">
              <div className="recharge-tile">                
                <div className="summary-subtitle"><FaHistory className="icon-recharge"/><span className="summary-title">{todayRechargeAmount}</span> Today's Recharge Amount</div>
              </div>
            </div>
          </div>










          <div className="row">
            <div className="col-4" onClick={fnAdminClick}>
              <div className="card-a"><div className="icon-card"><FaUserShield /></div> <div className="mt-2">Admins</div></div>
            </div>
            <div className="col-4" onClick={fnManageOrganizationClick}>
              <div className="card-b">
                <div className="icon-card"><FaUsersCog /></div> <div className="mt-2">Organizations</div>
              </div>
            </div>
            <div className="col-4" onClick={fnCreateDeviceClick}>
              <div className="card-c">
                <div className="icon-card"><FaLaptop /></div> <div className="mt-2">Devices</div>
              </div>
            </div>
            {/* <p className="menu-card" onClick={fnCreateCardClick}>Cards</p> */}
          </div>

        </div>
      </div>
    </div>
  );
};

export default Dashboard;