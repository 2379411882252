// src/AdminRegistration.js

import React, { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import axios from "axios";

const CreateAdmin = () => {
  const [IsLoading, setIsLoading] = useState(false);
  const [values, setValues] = useState({ name: '', mobile: '', email: '', address1: '', address2: '' })
  const [errors, setErrors] = useState({});
  const [redvalue, setredvalue] = useState("");

  const handleInput = async (event) => {
    const dataObj = { ...values, [event.target.name]: event.target.value }
    setValues(dataObj)
  };

  const navigate = useNavigate();

  function Validation(values) {
    const errors = {};
    let validationFlag = true;
    if (values.name === "") {
      errors.name = "Name is required";
      errors.validationFlag = false;
    }
    if (values.mobile === "") {
      errors.mobile = "Mobile is required!";
      errors.validationflag = false;
    }
    if (values.email === "") {
      errors.email = "Email is required!";
      errors.validationFlag = false;
    }
    if (values.address1 === "") {
      errors.address1 = "Address line 1 is required!";
      errors.validationFlag = false;
    }
    return errors;
  }

  const handleMobileChange = (event) => {

    const result = event.target.value.replace(/\D/g, '');
    setValues({ mobile: '' });
  }

  function handleSubmit(event) {

    event.preventDefault();
    const errorMsg = Validation(values)
    setErrors(errorMsg);

    if (errorMsg.validationFlag == false) { return false; }

    var data = JSON.stringify({
      "name": values.name,
      "mobile": values.mobile,
      "email": values.email,
      "address_1": values.address1,
      "address_2": values.address2
    });
    const BASE_URL = window.BASE_URL + '/add_admin';
    setIsLoading(true);
    var config = {
      method: 'post',
      url: BASE_URL,

      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem('jwtToken'),
        'Content-Type': 'application/json',
      },
      maxBodyLength: Infinity,
      data: data
    };
    axios.request(config)
      .then((response) => {

        if (response != null && response.data != null && response.data.results != null) {
          setIsLoading(false);
          setredvalue("Admin is created successfully");
          navigate("/SuperAdmin/ManageAdmin");
        }
        else {
          setIsLoading(false);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error);
      });
  };

  useEffect(() => {
    let TokenVal = localStorage.getItem('jwtToken');
    if (TokenVal === null || TokenVal === "" || TokenVal === undefined) {
      navigate("/");
    }
  });

  return (
    <div className="container-fluid">
      <div className="form-outer">
        <div className="super-admin-header">
          <h1>Register Admin</h1>
        </div>
        <div className="p-4">
          <form onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-md-4 col-12 mb-3">
                <label className="form-label">Name</label>
                <input className="form-control" type="text" placeholder="Admin Name" name="name" onChange={handleInput} />
                {errors.name && <span className="span-error">{errors.name}</span>}
              </div>
              <div className="col-md-4 col-12 mb-3">
                <label className="form-label">Mobile Number</label>
                <input className="form-control" type="text" placeholder="9337973843" name="mobile" maxLength={10} onInput={handleMobileChange} onChange={handleInput} />
                {errors.mobile && <span className="span-error">{errors.mobile}</span>}
              </div>
              <div className="col-md-4 col-12 mb-3">
                <label className="form-label">E-Mail</label>
                <input className="form-control" type="email" placeholder="admin@gmail.com" name="email" onChange={handleInput} />
                {errors.email && <span className="span-error">{errors.email}</span>}
              </div>
              <div className="col-md-4 col-12 mb-3">
                <label className="form-label">Address Line 1</label>
                <input className="form-control" type="text" placeholder="House/Flat/Plot No., Street/Road" name="address1" onChange={handleInput} />
                {errors.address1 && <span className="span-error">{errors.address1}</span>}
              </div>
              <div className="col-md-4 col-12 mb-3">
                <label className="form-label">Address Line 2</label>
                <input className="form-control" type="text" placeholder="Area, District, State - Postal code" name="address2" onChange={handleInput} />
              </div>
              <div className="col-12 text-center">
              <button type="submit" className="super-admin-button">Submit</button>
              </div>
              <p className="text-danger">{redvalue}</p>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default CreateAdmin;
