import React, { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import axios from "axios";
import { Modal, Button } from 'react-bootstrap';

const ManageAdmin = () => {
  const [AdminListData, setAdminListData] = useState([]);
  const navigate = useNavigate();
  const [adminData, setAdminData] = useState({});
  const [deviceData, setDeviceData] = useState('');
  const [cardData, setCardData] = useState('');
  const [allocatedDevices, setAllocatedDevices] = useState([]);
  const [availableDevices, setAvailableDevices] = useState([]);

  const [IsCheckBox, setIsCheckBox] = useState(false);
  const [IsBtnCancel, setIsBtnCancel] = useState(false);
  const [IsBtnDelete, setIsBtnDelete] = useState(false);
  const [IsBtnAddMore, setIsBtnAddMore] = useState(false);

  const [IsAllDelete, setIsAllDelete] = useState(true);
  const [IsCheckAllBox, setIsCheckAllBox] = useState(false);
  const [checked, setChecked] = useState({});
  const [checkedAll, setCheckedAll] = useState(false);

  //popup
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [popupShow, setPopupShow] = useState(false);
  const handlePopupClose = () => setPopupShow(false);
  const handlePopupShow = () => setPopupShow(true);

  const [popupDeviceShow, setPopupDeviceShow] = useState(false);
  const handlePopupDeviceClose = () => setPopupDeviceShow(false);
  const handlePopupDeviceShow = () => setPopupDeviceShow(true);

  useEffect(() => {
    let TokenVal = localStorage.getItem('jwtToken');
    if (TokenVal === null || TokenVal === "" || TokenVal === undefined) {
      navigate("/");
    }
    setIsBtnAddMore(true);
    setIsCheckAllBox(false);
    const interval = setInterval(() => {
      fnAdmininfoLoad();
    }, 500);
    return () => clearInterval(interval);
  }, []);

  //Devices
  useEffect(() => {
    let allChecked = true;
    for (const inputName in checked) {
      if (checked[inputName] === false) {
        allChecked = false;
      }
    }
    if (allChecked) {
      setCheckedAll(true);
    } else {
      setCheckedAll(false);
    }
  }, [checked]);

  // List of admin
  function fnAdmininfoLoad() {

    // var axios = require('axios');
    const BASE_URL = window.BASE_URL + '/get_admins_info';
    var config = {
      method: 'get',
      url: BASE_URL,
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem('jwtToken'),
      }
    };
    axios.request(config)
      .then(function (response) {
        setAdminListData([]);

        if (response != null && response != undefined && response.data != null && response.data != undefined && response.data.results != null && response.data.results.length > 0)
          setAdminListData(response.data.results);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  //Create Admin
  const fnCreateAdminClick = (e) => {
    navigate("/SuperAdmin/CreateAdmin");
  };

  //Count of devices and cards
  const fnAdmininfoClick = (Values, e) => {
    setAdminData(Values);
    var data = JSON.stringify({
      "admin_id": Values.admin_id,
    });

    const BASE_URL = window.BASE_URL + '/get_InventryCounts';
    //setIsLoading(true);
    var config = {
      method: 'post',
      url: BASE_URL,

      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem('jwtToken'),
        'Content-Type': 'application/json',
      },
      maxBodyLength: Infinity,
      data: data
    };

    axios.request(config)
      .then((response) => {

        if (response != null && response != undefined && response.data != null && response.data != undefined && response.data.results != null) {
          setDeviceData(response.data.results.totalDevices);
          setCardData(response.data.results.totalCards);
        }
      })
      .catch((error) => {
        // setIsLoading(false);
        console.log(error);
      });
    handleShow();
  };

  // Devices check box
  function fnCommontoggleclick(inputName, index) {
    setChecked((prevState) => {
      const newState = { ...prevState };
      newState[inputName] = !prevState[inputName];
      return newState;
    });
  }

  function fnCommonSelectclick(value) {
    setCheckedAll(value);
    setChecked((prevState) => {
      const newState = { ...prevState };
      for (const inputName in newState) {
        newState[inputName] = value;
      }
      return newState;
    });
  }
  const toggleCheck = (input, inx) => {
    fnCommontoggleclick(input, inx)
  };

  const selectAll = (tempval) => {
    fnCommonSelectclick(tempval)
  };

  function fnDeviceClick() {
    var data = JSON.stringify({
      "admin_id": adminData.admin_id,
    });

    const BASE_URL = window.BASE_URL + '/get_allocated_devices';
    //setIsLoading(true);
    var config = {
      method: 'post',
      url: BASE_URL,

      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem('jwtToken'),
        'Content-Type': 'application/json',
      },
      maxBodyLength: Infinity,
      data: data
    };

    axios.request(config)
      .then((response) => {

        setAllocatedDevices([]);
        if (response != null && response != undefined && response.data != null && response.data != undefined && response.data.results != null && response.data.results.length > 0) {
          var tempdata = response.data.results;
          setAllocatedDevices(tempdata);
          for (var i = 0; i < tempdata.length; i++) {
            var temp = tempdata[i].id;
            checked[temp] = false;
          }
          setCheckedAll(false);
        }
      })
      .catch((error) => {
        // setIsLoading(false);
        console.log(error);
      });

    handlePopupShow();
  };

  function fnRemoveClick() {
    setIsCheckBox(true);
    setIsAllDelete(false);
    setIsCheckAllBox(true);
    setIsBtnAddMore(false);
    setIsBtnDelete(true);
    setIsBtnCancel(true);
  };
  const fnRemoveDeviceClick = () => {

    let reqArr = [];
    setChecked((prevState) => {
      const newState = { ...prevState };
      for (let prop in newState) {
        if (newState.hasOwnProperty(prop)) {
          if (newState[prop] === true) {
            reqArr.push(prop);
          }
        }
      }
      if (reqArr != null && reqArr != [] && reqArr.length > 0) {
        var data = JSON.stringify({
          "admin_id": adminData.admin_id,
          "device_ids": reqArr
        });

        const BASE_URL = window.BASE_URL + '/remove_devices';
        //setIsLoading(true);

        var config = {
          method: 'post',
          url: BASE_URL,

          headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('jwtToken'),
            'Content-Type': 'application/json',
          },
          maxBodyLength: Infinity,
          data: data
        };

        axios.request(config)
          .then((response) => {

            if (response != null && response != undefined && response.data != null && response.data != undefined && response.data.results != null) {
              let tempArr = [];
              tempArr = [...allocatedDevices];
              const resp = response.data.results
              const arrRes = Object.keys(resp).map(i => Number(i));
              let res = tempArr.filter(item => !arrRes.includes(item.id));
              setDeviceData(res.length);
              setAllocatedDevices(res);
            }
          })
          .catch((error) => {
            // setIsLoading(false);
            console.log(error);
          });
      }
      return newState;
    });
  }

  function fnCancelClick() {
    fnDevicecommonclosePopup();
    fnCommonClosePopup();
  }
  function fnDeviceclosePopup() {
    fnDevicecommonclosePopup();
    fnCommonClosePopup();
    handlePopupClose();
  }

  function fnDevicecommonclosePopup() {
    setIsBtnCancel(false);
    setIsBtnDelete(false);
    setIsBtnAddMore(true);
    setIsCheckBox(false);
    setIsAllDelete(true);
  }

  function fnCloseAddMorePopup() {

    fnCommonClosePopup();
    handlePopupDeviceClose();
  }

  function fnCommonClosePopup() {
    setIsCheckAllBox(false);
    setCheckedAll(false);
    setChecked((prevState) => {
      const newState = { ...prevState };
      for (const inputName in newState) {
        newState[inputName] = false;
      }
      return newState;
    });
  }

  // Add more devices
  function fnAddMoreClick() {

    var data = JSON.stringify({
      "admin_id": 0,
    });

    const BASE_URL = window.BASE_URL + '/get_available_devices';
    //setIsLoading(true);
    var config = {
      method: 'post',
      url: BASE_URL,

      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem('jwtToken'),
        'Content-Type': 'application/json',
      },
      maxBodyLength: Infinity,
      data: data
    };

    axios.request(config)
      .then((response) => {
        setAvailableDevices([]);
        if (response != null && response != undefined && response.data != null && response.data != undefined && response.data.results != null && response.data.results.length > 0) {

          var tempdata = response.data.results;
          setAvailableDevices(tempdata);
          for (var i = 0; i < tempdata.length; i++) {
            var temp = tempdata[i].id;
            checked[temp] = false;
          }
          setCheckedAll(false);
        }
      })
      .catch((error) => {
        // setIsLoading(false);
        console.log(error);
      });

    handlePopupDeviceShow();
  }

  // Add more check boxes
  const toggleCheckDevices = (input, inx) => {
    fnCommontoggleclick(input, inx);
  }
  const selectAllDevices = (tempval) => {
    fnCommonSelectclick(tempval)
  }

  //Allocate devices
  const fnAllocateNowClick = () => {

    let reqArr = [];

    setChecked((prevState) => {
      const newState = { ...prevState };
      for (let prop in newState) {
        if (newState.hasOwnProperty(prop)) {
          if (newState[prop] === true) {
            reqArr.push(prop);
          }
        }
      }

      if (reqArr != null && reqArr != [] && reqArr.length > 0) {
        var data = JSON.stringify({
          "admin_id": adminData.admin_id,
          "device_ids": reqArr
        });

        const BASE_URL = window.BASE_URL + '/allocate_device';
        //setIsLoading(true);

        var config = {
          method: 'post',
          url: BASE_URL,

          headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('jwtToken'),
            'Content-Type': 'application/json',
          },
          maxBodyLength: Infinity,
          data: data
        };

        axios.request(config)
          .then((response) => {
            if (response != null && response != undefined && response.data != null && response.data != undefined && response.data.results != null) {
              let tempArr = [];
              let tempRes = [];
              tempArr = [...availableDevices];
              const resp = response.data.results
              let arrRes = Object.keys(resp).map(i => Number(i));
              let tempAvailableD = tempArr.filter(item => !arrRes.includes(item.id));
              const tempAllocatedD = tempArr.filter(index => {
                return arrRes.find(selected => selected === index.id)
              });
              tempRes = [...allocatedDevices, ...tempAllocatedD];
              setAllocatedDevices(tempRes);
              setDeviceData(tempRes.length);
              setAvailableDevices(tempAvailableD);
            }
          })
          .catch((error) => {
            // setIsLoading(false);
            console.log(error);
          });
      }
      return newState;
    });
  }

  // Cards
  function fnCardClick() {
    debugger;
  }

  return (
    <div className="container">
      <>
        {/* <Button variant="primary" onClick={handleShow}>
        Launch static backdrop modal
      </Button> */}

        <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false}>
          <Modal.Header closeButton>
            <Modal.Title>Admin</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="mb-2">
              <label className="form-label fw-bold">Name:</label>
              <label className="form-label">{adminData.name}</label>
            </div>
            <div className="mb-2">
              <label className="form-label fw-bold">Mobile:</label>
              <label className="form-label">{adminData.mobile}</label>
            </div>
            <div className="mb-2">
              <label className="form-label fw-bold">Email:</label>
              <label className="form-label">{adminData.email}</label>
            </div>
            <div className="mb-2">
              <label className="form-label fw-bold">Address:</label>
              <label className="form-label">{adminData.address_1 + ', ' + adminData.address_2}</label>
            </div>
            <div className="mb-2">
              <button variant="primary" onClick={fnDeviceClick}>Devices<br></br>{deviceData}</button>
              <button variant="primary" onClick={fnCardClick}>Cards<br></br>{cardData}</button>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </>
      <Modal show={popupShow} onHide={fnDeviceclosePopup} backdrop="static" keyboard={false}>
        <Modal.Header closeButton>
          <Modal.Title>Admin</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <table className="">
            <thead>
              <tr className="">
                <th>Devices</th>
                <th><Button style={IsAllDelete ? {} : { display: 'none' }} variant="secondary" onClick={fnRemoveClick}>Delete</Button>
                  <input style={IsCheckAllBox ? {} : { display: 'none' }} type="checkbox" onChange={(event) => selectAll(event.target.checked)} checked={checkedAll} />
                </th>
              </tr>
            </thead>
            <tbody>
              {allocatedDevices.map((Data, idx) => (
                <tr className="" key={idx}>
                  <td>{Data.name}</td>
                  <td style={IsCheckBox ? {} : { display: 'none' }}> <input type="checkbox" onChange={() => toggleCheck(Data.id, idx)} checked={checked[Data.id]} /></td>
                </tr>
              ))}
            </tbody>
          </table>
        </Modal.Body>
        <Modal.Footer>
          <Button style={IsBtnCancel ? {} : { display: 'none' }} variant="secondary" onClick={fnCancelClick}>
            Cancel
          </Button>
          <Button style={IsBtnDelete ? {} : { display: 'none' }} variant="secondary" onClick={fnRemoveDeviceClick}>
            Delete
          </Button>
          <Button style={IsBtnAddMore ? {} : { display: 'none' }} variant="secondary" onClick={fnAddMoreClick}>
            Add More
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Allocate Devices popup */}
      <Modal show={popupDeviceShow} onHide={fnCloseAddMorePopup} backdrop="static" keyboard={false}>
        <Modal.Header closeButton>
          <Modal.Title>Allocate Devices</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <table className="">
            <thead>
              <tr className="">
                <th>Select All</th>
                <th>
                  <input type="checkbox" onChange={(event) => selectAllDevices(event.target.checked)} checked={checkedAll} />
                </th>
              </tr>
            </thead>
            <tbody>
              {availableDevices.map((Data, idx) => (
                <tr className="" key={idx}>
                  <td>{Data.name}</td>
                  <td> <input type="checkbox" onChange={() => toggleCheckDevices(Data.id, idx)} checked={checked[Data.id]} /></td>
                </tr>
              ))}
            </tbody>
          </table>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={fnAllocateNowClick}>
            ALLOCATE NOW
          </Button>
        </Modal.Footer>
      </Modal>
      <div className="form-outer">
        <div className="super-admin-header">
          <h1>Manage Admin</h1>
        </div>
        <div className="p-4">
        <h4 className="fw-bold mb-3">List of Admins</h4>
          <table className="table table-hover border">
            <thead className="table-secondary">
              <tr className="">
                <th>Name</th>
                <th>Mobile</th>
                <th>Email</th>
                <th>Address</th>
              </tr>
            </thead>
            <tbody>
              {AdminListData
                //.filter((value) => {
                // if (SubmissionType === "ALL" && SubmissionStatus === "ALL") {
                //     if (searchedVal) {
                //         return value.customer_name.toLowerCase().includes(searchedVal.toLowerCase());
                //     }
                //     return true;
                // }
                // else if (SubmissionType && SubmissionStatus === "ALL") {
                //     if (searchedVal) {
                //         return value.customer_name.toLowerCase().includes(searchedVal.toLowerCase());
                //     }
                //     return value.submition_type === SubmissionType;
                // }

                // else if (SubmissionStatus) {
                //     if (searchedVal) {
                //         return value.customer_name.toLowerCase().includes(searchedVal.toLowerCase());
                //     }
                //     return value.submission_status.toLowerCase() === SubmissionStatus.toLowerCase();
                // }
                // else {
                //     if (searchedVal) {
                //         return value.customer_name.toLowerCase().includes(searchedVal.toLowerCase());
                //     }
                //     return true;
                // }

                //})
                .map((Data, idx) => (
                  <tr className="" id="addr0" key={idx} onClick={() => fnAdmininfoClick(Data, idx)}>
                    <td>{Data.name}</td>
                    <td>{Data.mobile}</td>
                    <td>{Data.email}</td>
                    <td>{Data.address_1 + ', ' + Data.address_2}</td>
                    {/* <td><button variant="primary" onClick={() => fnAdmininfoClick(idx)}>
                                            Admin Details
                                        </button></td>  */}
                    {/*<td style={UserData.status_type === "APPROVED" ? { backgroundColor: 'green' } : UserData.status_type === "REJECTED" ? { backgroundColor: 'red' } : { backgroundColor: 'blue' } >{ UserData.status_type }</td>*/}
                  </tr>
                ))}
            </tbody>
          </table>
        <div className="text-center"><button className="super-admin-button" onClick={fnCreateAdminClick}>CREATE NEW ADMIN</button></div>
      </div>
        </div>
    </div>
  );
};

export default ManageAdmin;