import React, { useState } from "react";
import { useNavigate } from 'react-router-dom';
import axios from "axios";
import loginImg from '../src/images/login-account.jpg';

const Login = () => {
  const [IsLoading, setIsLoading] = useState(false);
  const [mobile, setMobile] = useState("");
  const [password, setPassword] = useState("");
  const [userval, setuserval] = useState();
  const [passval, setpassval] = useState();
  const [redvalue, setredvalue] = useState("");
  let user = React.createRef();
  let pass = React.createRef();

  const handleMobileChange = (event) => {
    const result = event.target.value.replace(/\D/g, '');
    setMobile(result);
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    if (user.current.value === "" || user.current.value === null || user.current.value === undefined) {
      setuserval(true);
      setredvalue("Enter user name");
      return false;
    }

    if (pass.current.value === "" || pass.current.value === null || pass.current.value === undefined) {
      setpassval(true);
      setredvalue("Enter Password");
      return false;
    }
    var data = JSON.stringify({
      "user_name": user.current.value,
      "password": pass.current.value
    });
    //const axios = require('axios')
    const BASE_URL = window.BASE_URL + '/login';
    setIsLoading(true);
    var config = {
      method: 'post',
      url: BASE_URL,
      maxBodyLength: Infinity,

      headers: {
        'Content-Type': 'application/json',
      },
      data: data
    };
    //setTempDisable(true);
    axios.request(config)
      .then((response) => {
        if (response != null && response.data != null && response.data.results != null) {
          saveTokenInLocalStorage(response.data.results.token);
          localStorage.setItem("userid", response.data.results.userInfo.super_admin_id)
          navigate("/SuperAdmin/Dashboard");
          setIsLoading(false);
        }
        else if (response.data.code == 401) {
          setredvalue("Invalid username or password");
          setIsLoading(false);
        }
        else {
          setIsLoading(false);
        }
        //console.log(JSON.stringify(response.data));
      })
      .catch((error) => {
        setredvalue(error.message);
        setIsLoading(false);
        console.log(error);
      });
  };

  const navigate = useNavigate();
  const ChangePassword = () => {
    navigate("/ChangePassword");
  }

  return (

    // <div className="dashboard">
    // <div className="header">Dashboard</div>
    <div className="container mt-5">
      <div className="loader" style={IsLoading ? {} : { display: 'none' }}>
        <div className="lds-spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
      </div>

      <div className="row justify-content-center">
        <div className="col-md-6 col-12">
          <img className="login-img" src={loginImg} />
        </div>
        <div className="col-md-4 col-12">
          <div className="form-outer p-3">
          <h1 className="text-center">Login</h1>
          <div className="form-group">
            <input type="text" placeholder="Phone Number" onChange={handleMobileChange} className="max-input" value={mobile} maxLength={10} ref={user} />
            <input type="password" placeholder="Password" className="max-input" value={password} ref={pass} onChange={(e) => setPassword(e.target.value)} />
            <button className="max-button" onClick={handleSubmit}>LOGIN</button>
            <br />
            <p className="text-danger">{redvalue}</p>
            <div className="forgot-password text-center" onClick={ChangePassword}>Forgot Password</div>
          </div>
        </div>
      </div>
      </div>





    </div>
  );
};

export default Login;

export function saveTokenInLocalStorage(tokenDetails) {
  localStorage.setItem('jwtToken', (tokenDetails));
}
