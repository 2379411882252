// src/RegisterOrganization.js

import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from 'react-router-dom';
import axios from "axios";

const CreateDevice = () => {
  const [IsLoading, setIsLoading] = useState(false);
  const [values, setValues] = useState({ devicenumber: '', devicetype: '', devicename: '', mobile: '', description: '', adminname: '', orgname: '', branchname: '' });
  const [errors, setErrors] = useState({});
  const [redvalue, setredvalue] = useState("");
  const [adminDdl, setAdminDdl] = useState([]);
  const [orgDdl, setOrgDdl] = useState([]);
  const [branchDdl, setBranchDdl] = useState([]);
  const adminVal = useRef('');
  const orgVal = useRef('');
  const branchVal = useRef('');
  const handleInput = (event) => {
    const dataObj = { ...values, [event.target.name]: event.target.value }
    setValues(dataObj)
  };

  const navigate = useNavigate();

  function Validation(values) {
    const errors = {};
    let validationFlag = true;
    if (values.devicenumber === "") {
      errors.devicenumber = "Device number is required";
      errors.validationFlag = false;
    }
    if (values.devicetype === "") {
      errors.devicetype = "Device type is required";
      errors.validationFlag = false;
    }
    if (values.devicename === "") {
      errors.devicename = "Device name is required";
      errors.validationflag = false;
    }
    if (values.mobile === "") {
      errors.mobile = "Mobile is required";
      errors.validationFlag = false;
    }
    if (values.description === "") {
      errors.description = "Description is required";
      errors.validationFlag = false;
    }
    if (adminVal.current === "" || adminVal.current === "Select Admin") {
      errors.adminname = "Admin is required";
      errors.validationFlag = false;
    }
    return errors;
  }

  function handleSubmit(event) {
    event.preventDefault();
    const errorMsg = Validation(values)
    setErrors(errorMsg);

    if (errorMsg.validationFlag == false) { return false; }
    const userId = localStorage.getItem('userid');
    var data = JSON.stringify({
      "device_no": values.devicenumber,
      "device_type": values.devicetype,
      "device_name": values.devicename,
      "device_desc": values.description,
      "mobile_no": values.mobile,
      "super_admin_id": userId,
      "admin_id": adminVal.current,
      "organization_id": orgVal.current,
      "area_id": branchVal.current
    });

    const BASE_URL = window.BASE_URL + '/add_device';
    setIsLoading(true);
    var config = {
      method: 'post',
      url: BASE_URL,

      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem('jwtToken'),
        'Content-Type': 'application/json',
      },
      maxBodyLength: Infinity,
      data: data
    };
    axios.request(config)
      .then((response) => {

        if (response != null && response.data != null && response.data.results != null) {
          setIsLoading(false);
          setredvalue("Organization is created successfully");
          navigate("/SuperAdmin/ManageOrganization");
        }
        else {
          setIsLoading(false);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error);
      });
  }
  useEffect(() => {
    let TokenVal = localStorage.getItem('jwtToken');
    if (TokenVal === null || TokenVal === "" || TokenVal === undefined) {
      navigate("/");
    }

    fnAdminDdlLoad();
  }, []);

  function fnAdminDdlLoad() {
    const BASE_URL = window.BASE_URL + '/get_admins';
    var config = {
      method: 'get',
      url: BASE_URL,
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem('jwtToken'),
      }
    };

    axios.request(config)
      .then(function (response) {
        setAdminDdl([]);

        if (response != null && response != undefined && response.data != null && response.data != undefined && response.data.results != null && response.data.results.length > 0) {
          setAdminDdl(response.data.results);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  function fnAdminddlChange(event) {

    adminVal.current = event.target.value;
    const adminId = adminVal.current;
    if (adminId != "" && adminId != "Select Admin") {
      var data = JSON.stringify({
        "admin_id": adminId,
      });
      const BASE_URL = window.BASE_URL + '/get_organizations';
      setIsLoading(true);
      var config = {
        method: 'post',
        url: BASE_URL,

        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('jwtToken'),
          'Content-Type': 'application/json',
        },
        maxBodyLength: Infinity,
        data: data
      };

      axios.request(config)
        .then((response) => {

          setOrgDdl([]);
          if (response != null && response != undefined && response.data != null && response.data != undefined && response.data.results != null && response.data.results.length > 0) {
            setOrgDdl(response.data.results);
          }
        })
        .catch((error) => {
          setIsLoading(false);
          console.log(error);
        });
    }
    else {
      setOrgDdl([]);
    }
  }

  function fnOrganizationChange(event) {
    orgVal.current = event.target.value;
    const orgId = orgVal.current;
    if (orgId != "" && orgId != "Select Organization") {
      var data = JSON.stringify({
        "organization_id": orgId,
      });
      const BASE_URL = window.BASE_URL + '/get_branches';
      setIsLoading(true);
      var config = {
        method: 'post',
        url: BASE_URL,

        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('jwtToken'),
          'Content-Type': 'application/json',
        },
        maxBodyLength: Infinity,
        data: data
      };

      axios.request(config)
        .then((response) => {

          setBranchDdl([]);
          if (response != null && response != undefined && response.data != null && response.data != undefined && response.data.results != null && response.data.results.length > 0) {
            setBranchDdl(response.data.results);
          }
        })
        .catch((error) => {
          setIsLoading(false);
          console.log(error);
        });
    }
    else {
      setBranchDdl([]);
    }
  }

  function fnBranchChange(event) {
    branchVal.current = event.target.value;
  }

  return (
    <div className="container-fluid">
      <div className="super-admin-container form-outer">
        <div className="super-admin-header mb-2">
          <h1>Register Device</h1>
        </div>
        <div className="p-4">
          <form onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-md-4 col-12">
                <div className="mb-3">
                  <label class="form-label">Device Number</label>
                  <input className="form-select" type="text" placeholder="383273131747373" name="devicenumber" maxLength={20} onChange={handleInput} />
                  {errors.devicenumber && <span className="span-error">{errors.devicenumber}</span>}
                </div>
              </div>
              <div className="col-md-4 col-12">
                <div className="mb-3">
                  <label class="form-label">Device Type</label>
                  <select className="form-select" name="devicetype" onChange={handleInput}>
                    <option>Select</option>
                    <option>Pay Phone</option>
                  </select>
                  {errors.devicetype && <span className="span-error">{errors.devicetype}</span>}
                </div>
              </div>
              <div className="col-md-4 col-12">
                <div className="mb-3">
                  <label class="form-label">Device Name</label>
                  <input className="form-select" type="text" placeholder="Device 1" name="devicename" onChange={handleInput} />
                  {errors.devicename && <span className="span-error">{errors.devicename}</span>}
                </div>
              </div>
              <div className="col-md-4 col-12">
                <div className="mb-3">
                  <label class="form-label">Mobile Number</label>
                  <input className="form-select" type="text" placeholder="9337973843" name="mobile" maxLength={10} onChange={handleInput} />
                  {errors.mobile && <span className="span-error">{errors.mobile}</span>}
                </div>
              </div>
              <div className="col-md-4 col-12">
                <div className="mb-3">
                  <label class="form-label">Description</label>
                  <input className="form-select" type="text" placeholder="About the Hardware Device" name="description" onChange={handleInput} />
                  {errors.description && <span className="span-error">{errors.description}</span>}
                </div>
              </div>
              <div className="col-md-4 col-12">
                <div className="mb-3">
                  <label class="form-label">Admin</label>
                  <select name="adminname" className="form-select" onChange={fnAdminddlChange}>
                    <option>Select Admin</option>
                    {adminDdl.map((p) => (
                      <option key={p.id} value={p.id}>
                        {p.name}
                      </option>
                    ))}
                  </select>
                  {errors.adminname && <span className="span-error">{errors.adminname}</span>}
                </div>
              </div>
              <div className="col-md-4 col-12">
                <div className="mb-3">
                  <label class="form-label">Organization</label>
                  <select className="form-select" name="orgname" onChange={fnOrganizationChange}>
                    <option>Select Organization</option>
                    {orgDdl.map((p) => (
                      <option key={p.id} value={p.id}>
                        {p.name}
                      </option>
                    ))}
                  </select>
                  {errors.orgname && <span className="span-error">{errors.orgname}</span>}
                </div>
              </div>
              <div className="col-md-4 col-12">
                <div className="mb-3">
                  <label>Branch</label>
                  <select className="form-select" name="branchname" onChange={fnBranchChange}>
                    <option>Select Branch</option>
                    {branchDdl.map((p) => (
                      <option key={p.branch_id
                      } value={p.branch_id
                      }>
                        {p.branch_name}
                      </option>
                    ))}
                  </select>
                  {errors.branchname && <span className="span-error">{errors.branchname}</span>}
                </div>
              </div>
              <div className="col-md-12 col-12 text-center my-3">
                <button type="submit" className="super-admin-button">Submit</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default CreateDevice;
