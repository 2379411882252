import React, { useState } from "react";
import { useNavigate } from 'react-router-dom';
import loginImg from '../src/images/login-account.jpg';

const ChangePassword = () => {

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();

  };

  const navigate = useNavigate();


  return (
    <div className="container mt-5">
      {/* <form onSubmit={handleSubmit}> */}

      <div className="row justify-content-center">
        <div className="col-md-6 col-12">
          <img className="login-img" src={loginImg} />
        </div>
        <div className="col-md-4 col-12">
          <div className="form-outer p-3">
            <h1 className="text-center mb-4">Forgot password</h1>
              <div className="form-group">
                <label>Email address</label>
                <input
                  type="email"
                  className="form-control"
                  placeholder="Enter email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              <div className="form-group">
                <label>Password</label>
                <input
                  type="password"
                  className="form-control"
                  placeholder="Enter password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </div>
              <button type="submit" className="max-button"> Submit</button>
            </div>
        </div>
      </div>
    </div>
  );

};
export default ChangePassword;